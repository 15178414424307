import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    submittingFormInProgress: false,
    submittingFormError: null,

    masterList: [],
    selectedMasterList: null,

    singleRelay: {},
    singleRelayMoniotoringObjects: [],

    formStatus: 'viewOnly',
    beforeEditData: null,

    removePopupVisible: false,
    addPopupVisible: false,
    popupVisible: null,
    rowsPerPage: 20,
};

const relaysSlice = createSlice({
    name: 'relays',
    initialState,
    reducers: {
        fetchMasterListStart() {},
        fetchMasterListSuccess(state, { payload: companyList }) {
            state.masterList = companyList;
        },
        fetchSingleRelay() {},
        fetchSingleRelaySuccess(state, { payload }) {
            state.singleRelay = payload;
        },

        // fetchRelayMonitoringObject() {},
        setRelayMonitoringObject(state, { payload }) {
            state.singleRelayMoniotoringObjects = payload;
        },
        setSelectedMasterListItem(state, { payload }) {
            state.selectedMasterList = payload ? state.masterList.find((comapny) => comapny.id === payload) : null;
        },
        submitFormStart(state) {
            state.submittingFormError = null;
        },
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        setBeforeEditData(state, { payload: personData = null }) {
            state.beforeEditData = personData;
        },
        resetState(state) {
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.formStatus = 'viewOnly';
            state.masterList = [];
            state.selectedMasterList = null;
            state.beforeEditData = null;
            state.selectedMasterList = null;
        },
        setRemovePopupVisible(state, { payload }) {
            state.removePopupVisible = payload;
        },
        setAddPopupVisible(state, { payload }) {
            state.addPopupVisible = payload;
        },
        addItemToMasterList() {},
        setPopupVisible(state, { payload: visible }) {
            state.popupVisible = visible;
        },
    },
});

export const relaysActions = relaysSlice.actions;
export const relaysReducer = relaysSlice.reducer;
