import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { CloseIconButton, CustomBody, CustomTitle } from './DialogStyled.js';

LastaDialog.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.node,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
    classes: PropTypes.string,
    sxDialog: PropTypes.object,
    sxTitle: PropTypes.object,
    sxTitleText: PropTypes.object,
    sxCloseButton: PropTypes.object,
    sxContent: PropTypes.object,
    actionBarRenderer: PropTypes.func,
    PaperProps: PropTypes.object,
};

function LastaDialog({
    icon,
    title,
    open,
    onClose = () => {},
    children,
    fullScreen,
    classes,
    PaperProps,
    sxDialog,
    sxTitle,
    sxTitleText,
    sxCloseButton,
    sxContent,
    actionBarRenderer,
}) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullScreen={fullScreen}
            className={classes}
            sx={{
                width: '100vw',
                '& [class*=MuiPaper-root]': {
                    maxWidth: 'max-content',
                    ...sxDialog,
                },
            }}
            PaperProps={PaperProps}
        >
            <Box>
                <CloseIconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        ...sxCloseButton,
                        color: 'grey',
                    }}
                >
                    <CloseIcon />
                </CloseIconButton>
                {title && (
                    <CustomTitle sx={sxTitle}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {icon}
                            <Typography variant="h6" sx={sxTitleText}>
                                {title}
                            </Typography>
                        </Stack>
                    </CustomTitle>
                )}
            </Box>
            <CustomBody sx={sxContent}>{children}</CustomBody>
            {actionBarRenderer && actionBarRenderer()}
        </Dialog>
    );
}

export default LastaDialog;
