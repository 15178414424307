import { getAdapter } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { createMemoryCacheStorage } from 'utils/cacheHelper/memoryCacheStorage';

const defaultAdapter = getAdapter('fetch');
const cacheStorage = createMemoryCacheStorage();

const pruneCache = async (keys) => {
    const tasks = keys.map((key) => cacheStorage.remove(key));
    return Promise.all(tasks);
};

export const axiosCacheAdapter = async (config) => {
    const { cache, url } = config;

    // Check if the request is cacheable
    const isCacheable = cache?.enabled;
    const cacheKey = cache?.key || url;
    if (isCacheable) {
        // Check if the response is already in the cache
        const cachedResponse = await cacheStorage.get(cacheKey, {
            expiration: cache?.expiration,
        });
        if (cachedResponse) {
            // Clone the cached response to avoid mutating in actual cache
            const clonedResponse = cloneDeep(cachedResponse);
            return Promise.resolve(clonedResponse);
        }
    }

    // Use Axios default adapter to perform the request
    const response = await defaultAdapter(config);

    // Prune the cache if the request has a prune list
    if (cache?.prune?.length) {
        await pruneCache(cache.prune);
    }

    // Cache the response
    if (isCacheable) {
        await cacheStorage.set(cacheKey, response);
    }
    return response;
};

export const clearAxiosAdapterCache = async () => {
    return cacheStorage.clear();
};
