import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from './authSlice';
import axios from 'axios';
import store from 'store/store';
import { axiosSSO } from 'config';
import { axiosLasta } from 'config';
import { sendLocalStorageSignal } from 'utils/sessionStorageHandlers';
import notificationsHandler from 'utils/notificationsHandler';
import { clearAxiosAdapterCache } from 'utils/cacheHelper/axiosCacheAdapter';

const handleResponseError = (error) => {
    const resStatus = error.response?.status;
    const initiateLogout = resStatus === 401 || resStatus === 403;
    if (initiateLogout) store.dispatch(authActions.handleUnauthorized());
    return Promise.reject(error);
};

axios.interceptors.response.use((response) => response, handleResponseError);
axiosLasta.interceptors.response.use((response) => response, handleResponseError);

function* getUserData() {
    try {
        const response = yield axiosLasta('/auth');
        let { username, roles, userId, email } = response?.data || {};
        if (roles?.length) {
            const user = { username, roles, id: userId, email };
            yield put(authActions.setUser(user));
        }
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: 'Error getting user data!',
        });
    }
    yield put(authActions.setIsUserDataRequested(true));
}

function* handleUnauthorized() {
    try {
        yield axiosLasta('/auth');
        yield call(notificationsHandler, {
            title: 'You dont have permission!',
            variant: 'warning',
        });
    } catch (err) {
        yield call(logout);
    }
}

function* logout() {
    try {
        sendLocalStorageSignal('UserLoggedOut');
        yield put(authActions.setUser(null));
        sessionStorage.clear();
        yield clearAxiosAdapterCache();
        yield axiosSSO.post('/logout');
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: 'Logout error.',
        });
    }
}

function* onGetUserData() {
    yield takeLatest(authActions.getUserData.type, getUserData);
}

function* onHandleUnauthorized() {
    yield takeLatest(authActions.handleUnauthorized.type, handleUnauthorized);
}

function* onLogoutStart() {
    yield takeLatest(authActions.logoutStart.type, logout);
}

export function* authSaga() {
    yield all([call(onGetUserData), call(onLogoutStart), call(onHandleUnauthorized)]);
}
