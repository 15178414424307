import { put, select } from 'redux-saga/effects';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import * as authorizedPeopleSelectors from 'store/peopleAuthorizedList/peopleAuthorizedListsSelectors';
import { axiosLasta } from 'config';
import { createPeopleFormPayload, updatePeopleFormPayload } from 'store/peopleAuthorizedList/peopleAuthorizedListMapper';
import store from 'store/store';
import { getFormattedErrorMessage, getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';

export function* FormSaga({ payload: type }) {
    try {
        const formId = type.formId;
        const {
            form: { values, errors },
        } = yield select(selectFormById(formId));
        let isFormValid = true;

        Object.keys(errors).forEach((fieldName) => {
            if (errors[fieldName]) {
                isFormValid = false;
                store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
            }
        });

        if (!isFormValid) {
            yield notificationsHandler({
                title: 'Please fill required fields',
                variant: 'warning',
            });
            yield put(type.actions.submitFormFailed());
            return;
        }

        let message;

        if (type.formStatus === 'add') {
            if (type.formId === 'peopleAuthrizedAddAlerts') {
                const authorizations = yield select(authorizedPeopleSelectors.selectAuthoriziedPersonAlerts);
                const data = {
                    ...type.authorizedPerson,
                    ...type.location,
                    authType: {
                        id: values.name,
                    },
                };

                if (values.authorizedBy) {
                    data.authorizedBy = {
                        id: values.authorizedBy,
                    };
                }
                if (values.authorizedVia) {
                    data.authorizedVia = values.authorizedVia;
                }
                if (values.note) {
                    data.note = values.note;
                }
                if (authorizations.find((el) => el.id === values.name)) {
                    yield notificationsHandler({
                        title: 'Authorization already exist for company',
                    });
                    yield put(type.actions.submitFormFailed());
                    return;
                } else {
                    const response = yield axiosLasta.post(type.postUrl, data);
                    ({ message } = response.data);
                }
            } else if (type.formId === 'peopleInfo' && !/^[A-Za-z0-9]*$/.test(values.companyCardNumber)) {
                yield notificationsHandler({
                    title: 'Invalid Company Card Number!',
                });
                yield put(type.actions.submitFormFailed());
                return;
            } else if (type.formId === 'employeeForm') {
                const data = {
                    ...values,
                };
                if (values.division) {
                    data.division = {
                        id: values.division,
                    };
                }
                delete data.englishName;
                const response = yield axiosLasta.post(type.postUrl, data);
                ({ message } = response.data);
            } else {
                const data = createPeopleFormPayload(values, type);
                const response = type.postService ? yield type.postService(data) : yield axiosLasta.post(type.postUrl, data);
                if (response.status === 200) {
                    yield put(type.actions.setAddedCompany(true));
                }
                ({ message } = response.data);
            }
        }
        if (type.formStatus === 'edit') {
            const beforeEditData = type.beforeEditData;

            if (type.formId === 'peopleAuthrizedPersonInfo' && !/^[A-Za-z0-9]*$/.test(values.companyCardNumber)) {
                yield notificationsHandler({
                    title: 'Invalid Company Card Number!',
                });
                yield put(type.actions.submitFormFailed());
                return;
            } else if (type.formId === 'employeeForm') {
                const data = {
                    ...values,
                };
                if (values.division) {
                    data.division = {
                        id: values.division,
                    };
                }
                delete data.englishName;
                const response = yield axiosLasta.put(type.putUrl, {
                    ...data,
                    id: beforeEditData.id,
                });
                ({ message } = response.data);
            } else {
                const body = updatePeopleFormPayload(values, type, beforeEditData?.id);
                const response = yield axiosLasta.put(type.putUrl, body);
                ({ message } = response.data);
            }
        }

        if (message.includes('already exist')) {
            yield put(
                formControlActions.setFieldError({
                    formId,
                    fieldName: type.fields.name.fieldName,
                    error: `${type.formFor} already exists`,
                })
            );
            yield put(type.actions.submitFormFailed());
            return;
        }

        if (message.includes('Everything went well') && type.formId === 'employeeForm') {
            const idMatch = message.match(/Employee\[.*,id=(\d+),/);
            const Id = idMatch ? parseInt(idMatch[1], 10) : 0;

            yield put(type.actions.fetchEmployeesStart(Id));
            yield put(type.actions.setFormStatus('viewOnly'));
            yield put(type.actions.setPopupVisible(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} EMPLOYEE`,
                variant: 'success',
            });
        }

        if (message.includes('SUCCESS') && type.formId === 'peopleInfo') {
            yield put(type.actions.fetchPersonStart());
            yield put(type.actions.setAddPopupVisible(false));
            yield put(type.actions.setPopupVisible(false));
            yield put(type.actions.fetchAuthorizedPersonPerLocation({ id: type.locationId }));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} PERSON`,
                variant: 'success',
            });
        }

        if (message.includes('SUCCESS') && type.formId === 'peopleAuthrizedPersonInfo') {
            yield put(type.actions.setFormStatus('viewOnly'));
            yield put(type.actions.fetchAuthorizedPersonPerLocation(type.locationId));
            yield put(type.actions.setPopupVisible(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} AUTHORIZATION FOR PERSON`,
                variant: 'success',
            });
        }

        if (message.includes('SUCCESS') && type.formId === 'peopleAuthrizedAddAlerts') {
            yield put(type.actions.setAddAuthTypePopupVisible(false));
            yield put(type.actions.fetchAuthorizedPersonPerLocation(type.locationId));
            const getAuthTypeIds = {
                personId: type.authorizedPerson.authorizedPerson.id,
                locationId: type.locationId.id,
            };
            yield put(type.actions.fetchPersonAuthTypesStart(getAuthTypeIds));

            if (type.successId === 'addAuthorizationPerson') {
                yield put(type.actions.setAuthTypePopupVisible(false));
                yield put(type.actions.setSelectedPerson(type.authorizedPerson.authorizedPerson.id));
                yield put(type.actions.setSelectedAuthorizedPerson(type.authorizedPerson.authorizedPerson.id));
            }

            yield notificationsHandler({
                title: `SUCCESSFULLY ADDED AUTHORIZATION FOR PERSON`,
                variant: 'success',
            });
        }
        yield put(type.actions.submitFormSuccess());
    } catch (err) {
        if (err?.response?.status === 400 && type.formId === 'peopleInfo') {
            const formattedError = getFormattedErrorMessage(err?.response?.data?.error);
            yield notificationsHandler({ title: formattedError });
            yield put(type.actions.submitFormFailed());
            return;
        }

        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
        yield put(type.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}
