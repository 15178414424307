import { green, orange, red } from '@mui/material/colors';
// follow the RFC-5322 -> https://datatracker.ietf.org/doc/html/rfc5322
export const EMAIL_REGEX_FORMAT = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
export const DL_REGEX_FORMAT = /[^a-zA-Z0-9]/g;
export const NUMBER_ONLY_FORMAT = /\D/g;

export const DATA_TABLE_DEFAULT_PAGE_SIZE = 25;
export const ROLES = {
    ADMIN: 'admin',
    AGENT: 'agent',
    USER: 'user',
    HR: 'hr',
    HQ_SUPPORT: 'hq_support',
    FACILITY_MANAGER: 'facility_manager',
    SALES: 'sales',
    TECH: 'tech',
    ORGANIZATION_ADMIN: 'organization_admin',
};

export const USER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
};

export const PEOPLE_TITLE_LIST = [
    'Accountant',
    'Cleaner',
    'Cleaning Lady',
    'Customer Service Department',
    'Dispatcher',
    'Dock Operator',
    'Dock Supervisor',
    'Driver',
    'Driver (Owner Operator)',
    'Driver Instructor',
    'Electrician',
    'Equipment Manager',
    'GPS Installer',
    'Guard',
    'Maintenance',
    'Manager',
    'Mechanic',
    'Office Staff',
    'Operations Manager',
    'Owner',
    'Partner',
    'Property Manager',
    'Repair',
    'Safety & Compliance Co-Ordinator',
    'Safety Manager',
    'Secretary',
    'Shop Manager',
    'Shop Mechanic',
    'Shunt Driver',
    'Tech',
    'Vendor',
    'Warehouse Personnel',
    'Washer',
    'Worker',
    'Yard Manager',
];

export const PERIOD_ACCESS_TYPES = {
    ONE_TIME: 'ONE_TIME',
    DATE_PERIOD: 'DATE_PERIOD',
    TIME_PERIOD: 'TIME_PERIOD',
    WEEKDAYS: 'WEEKDAYS',
    PERMANENT: 'PERMANENT',
    NO_LIMIT: 'NO_LIMIT',
};

export const PERIOD_ACCESS_TYPE_NAMES = {
    [PERIOD_ACCESS_TYPES.ONE_TIME]: 'One Time',
    [PERIOD_ACCESS_TYPES.DATE_PERIOD]: 'Date Period',
    [PERIOD_ACCESS_TYPES.TIME_PERIOD]: 'Time Period',
    [PERIOD_ACCESS_TYPES.WEEKDAYS]: 'Weekdays',
    [PERIOD_ACCESS_TYPES.PERMANENT]: 'Ongoing',
};

export const INDUSTRY_OPTIONS = [
    {
        label: 'Automotive',
        value: 'AUTOMOTIVE',
    },
    {
        label: 'Trucking',
        value: 'TRUCKING',
    },
    {
        label: 'Residental',
        value: 'RESIDENTAL',
    },
];

export const RELATION_OPTIONS = [
    {
        label: 'Tenant',
        value: 'TENANT',
    },
    {
        label: 'Third Party Carrier',
        value: 'THIRD_PARTY_CARRIER',
    },
    {
        label: 'Vendor',
        value: 'VENDOR',
    },
];

export const ALERT_TYPES = {
    PRIVILEGED_COMPANY: 'Privileged Company',
    SPECIFIC_REQUESTS_COMPANY: 'Specific Requests Company',
    SPECIFIC_REQUESTS_ORGANIZATION: 'Specific Requests Organization',
    PRIVILEGED_ORGANIZATION: 'Privileged Organization',
    BANNED: 'Banned',
    INFORMATION: 'Information',
    SPECIALIZED_PICKUPS: 'Specialized pickups',
    DEFAULT: 'Default',
};

export const ROUTE_PATH = {
    SSO_CONFIGURATION: '/sso-configuration',
};

export const APPOINTMENT_STATUS_COLORS = {
    Inactive: red[500],
    Active: orange[500],
    Completed: green[500],
};

export const APPOINTMENT_STATUS = {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
    COMPLETED: 'Completed',
};

export const DEFAULT_PAGING = {
    PAGE_NUMBER: 0,
    PAGE_SIZE: 1_000_000,
};

export const FIELD_FILTER_OPERATIONS = [
    '=',
    '<>',
    '>',
    '<',
    '>=',
    '<=',
    'contains',
    'notcontains',
    'startswith',
    'endswith',
    'isblank',
    'isnotblank',
    'between',
    'anyof',
];

export const INVENTORY_STATUS = {
    GOOD_TO_GO: 'Good To Go',
    IN_SHOP: 'In Shop',
    GROUNDED: 'Grounded',
    IN_LEASE: 'In Lease',
    IN_SALE_TRADE: 'In Sale / Trade',
    ANNUAL_INSPECT: 'Annual Inspect',
    WASH: 'Wash',
    UNAVAILABLE: 'Unavailable',
    BREAKDOWN: 'Breakdown',
    TERMINATED: 'Terminated',
};

export const DATE_TIME_SELECTED_OPTIONS = {
    NO_DATE_SPEC: 'NoDateSpecific',
    SELECTED_DATE: 'SelectedDate',
};

export const CONFIRMATION_MESSAGES = {
    REMOVE_ASSET: 'Are you sure you want to remove this Asset from the Current Inventory?',
};
export const TEMP_FIELD_PREFIX = 'temp_field_';
export const APC_INVENTORY_LOOKUP_FIELDS = {
    'vehicle.unitNumber': 'Unit #',
    'vehicle.licensePlateNumber': 'License Plate #',
    'trailer.trailerNumber': 'Trailer #',
    'trailer.containerNumber': 'Container #',
    'trailer.chassisNumber': 'Chassis #',
    'trailer.dollyNumber': 'Dolly Number',
    'trailer.equipmentNumber': 'Equipment #',
};

export const INVENTOR_CATEGORY = {
    VEHICLE: 'VEHICLE',
    EQUIPMENT: 'EQUIPMENT',
};

export const GATE_DIRECTION = {
    IN: 'IN',
    OUT: 'OUT',
};

export const DYNAMIC_FORM_CAPTION = {
    CARGO: 'Cargo',
    GENERAL: 'General',
    OPTIONS: 'Options',
    SAFETY: 'Safety',
    ADDITIONAL: 'Additional',
    VEHICLE: 'Vehicle',
    COMPANY: 'Company',
    PERSON: 'Person',
    TRAILER: 'Trailer',
};
