import { axiosLasta } from 'config';

const CACHE_NAME_LOCATION_LIST = '/location/all';

export const getAllLocationList = async () => {
    const response = await axiosLasta('/location/all', {
        cache: {
            enabled: true,
            key: CACHE_NAME_LOCATION_LIST,
        },
    });
    const { data } = response;
    return data;
};

export const createLocation = async (payload) => {
    const response = await axiosLasta.post('/location', payload, {
        cache: {
            prune: [CACHE_NAME_LOCATION_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const updateLocation = async (payload) => {
    const response = await axiosLasta.put('/location', payload, {
        cache: {
            prune: [CACHE_NAME_LOCATION_LIST],
        },
    });
    const { data } = response;
    return data;
};
