import { axiosLasta } from 'config';
import { all, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as relaysListSelectors from 'store/relays/relaysListSelectors';

import notificationsHandler from 'utils/notificationsHandler';
import { FormLocationSaga } from 'store/formControl/formLocationSaga';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import { relaysActions } from './relaysListSlice';
import store from 'store/store';

export function* fetchMasterList(payload) {
    try {
        const rowsPerPage = yield select(relaysListSelectors.rowsPerPage);
        let isActive = payload.payload.isActive ? '&isActive=true' : '';
        let isConfigured = payload.payload.isConfigured ? '&isConfigured=true' : '';
        const response = yield axiosLasta(
            `/v2/monitoring/elements/relay/slots?size=${rowsPerPage}&page=${payload.payload.pageNumber}${isActive}${isConfigured}`
        );
        const { data } = response;

        data.content?.map((el) => {
            el.timestamp = new Date(el.timestamp).toUTCString();
            return el;
        });

        yield put(relaysActions.fetchMasterListSuccess(data.content ? data.content : []));
    } catch (err) {
        yield notificationsHandler({ title: 'Error fetching all locations' });
    }
}

export function* fetchSingleRelay(id) {
    try {
        const response = yield axiosLasta(`/v2/monitoring/elements/relay/slot/${id.payload}`);
        const data = { ...response.data, ...response.data.signalLabels };
        data.monitoringElementRelay.monitoringObjects &&
            data.monitoringElementRelay.monitoringObjects.map((el) => {
                el.title = `${el.name.trim()} (${el.objectType.trim()})`;
                return el;
            });
        data.monitoringObjects &&
            data.monitoringObjects.map((el) => {
                el.title = `${el.name.trim()} (${el.objectType.trim()})`;
                return el;
            });
        if (data['PULSE']) {
            data.activePULSE = true;
        }
        if (data['ON']) {
            data.activeON = true;
        }
        if (data['OFF']) {
            data.activeOFF = true;
        }

        yield put(relaysActions.fetchSingleRelaySuccess(data));
        yield put(relaysActions.setBeforeEditData(data));
    } catch (err) {
        yield notificationsHandler({
            title: 'Error fetching all SingleRelay',
        });
    }
}

export function* editAddRelayMonitoringObjectId({ payload: type }) {
    try {
        const formId = type.formId;
        const {
            form: { values, errors },
        } = yield select(selectFormById(formId));
        let isFormValid = true;

        Object.keys(errors).forEach((fieldName) => {
            if (errors[fieldName]) {
                isFormValid = false;
                store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
            }
        });

        if (!isFormValid) {
            yield notificationsHandler({
                title: 'Please fill required fields',
                variant: 'warning',
            });
            return;
        }

        let status;

        if (type.formStatus === 'add') {
            const response = yield axiosLasta.post(type.postUrl, values);
            status = response.status;
        }
        if (type.formStatus === 'edit') {
            const relaySlotId = yield select(relaysListSelectors.selectMasterListItem);

            let signalLabels = {};

            if (values.activeON) {
                signalLabels['ON'] = values['ON'] ? values['ON'] : 'ON';
            }
            if (values.activeOFF) {
                signalLabels['OFF'] = values['OFF'] ? values['OFF'] : 'OFF';
            }
            if (values.activePULSE) {
                signalLabels['PULSE'] = values['PULSE'] ? values['PULSE'] : 'PULSE';
            }

            let manipulatedData = {
                id: relaySlotId.id,
                signalLabels: signalLabels,
                name: values.name,
            };

            if (values.monitoringObjects) {
                manipulatedData.monitoringObjects = values.monitoringObjects?.map(({ id }) => ({ id }));
            }

            const response = yield axiosLasta.put(type.putUrl, manipulatedData);

            if (response.status === 200 && !response.data.active && values.monitoringObjects?.length > 0) {
                yield axiosLasta.put(`/v2/monitoring/elements/relay/slot/${relaySlotId.id}/activate`);
            }

            if (response.status === 200 && response.data.active && values.monitoringObjects.length === 0) {
                yield axiosLasta.put(`/v2/monitoring/elements/relay/slot/${relaySlotId.id}/deactivate`);
            }
            status = response.status;
        }

        if (status === 200) {
            yield put(type.actions.setFormStatus('viewOnly'));
            yield put(
                type.actions.fetchMasterListStart({
                    pageNumber: 0,
                    isActive: type.filterCriteria.statuses.isActive,
                    isConfigured: type.filterCriteria.statuses.isConfigured,
                })
            );
            yield put(type.actions.setAddPopupVisible(false));

            yield notificationsHandler({
                title: `SUCCESSFULLY ${type.formStatus === 'edit' ? 'EDITED' : 'ADDED'} RELAY SLOT`,
                variant: 'success',
            });
        }
    } catch (err) {
        const errMsg = err?.response?.data?.error ?? (err?.response?.data ? Object.values(err.response.data).join(', ') : undefined);
        yield notificationsHandler({
            title: `Error submitting data! ${errMsg}`,
        });
        yield put(type.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}

export function* onfetchSingleRelay() {
    yield takeEvery(relaysActions.fetchSingleRelay.type, fetchSingleRelay);
}

export function* onFetchingMasterListStart() {
    yield takeEvery(relaysActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(relaysActions.submitFormStart, editAddRelayMonitoringObjectId);
}

export function* onAddItemToMasterList() {
    yield takeLatest(relaysActions.addItemToMasterList.type, FormLocationSaga);
}

export function* relaysSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart), call(onfetchSingleRelay)]);
}
