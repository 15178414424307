import { axiosLasta } from 'config';

export const fetchAudioTypes = async () => {
    const response = await axiosLasta('/monitoring/camera/audio-types');
    const { data } = response;
    return data;
};
export const fetchAudioConfiguredCameras = async () => {
    const response = await axiosLasta('/monitoring/elements/camera/audio-configured');
    const { data } = response;
    return data;
};

export const fetchLocationMonitoringObjects = async (locationId) => {
    const response = await axiosLasta(`/monitoring/object/${locationId}`);
    const { data } = response;
    return data;
};

export const fetchMonitoringObjectCameras = async (monitoringObjectId) => {
    const response = await axiosLasta(`/monitoring/elements/camera/${monitoringObjectId}`);
    const { data } = response;
    return data;
};

export const updateCameraAudioType = async (body) => {
    await axiosLasta.put('/monitoring/elements/camera/audio-type', body);
};
