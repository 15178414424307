import { axiosLasta } from 'config';
import { toQueryString } from 'utils/queryStringHelpers';
import { CACHE_NAME_ORGANIZATION_LIST } from 'services/constants/cacheNameConstants';

export const getAllOrganizationList = async () => {
    const response = await axiosLasta.get('/organization/all', {
        cache: {
            enabled: true,
            key: CACHE_NAME_ORGANIZATION_LIST,
        },
    });
    const { data } = response;
    return data;
};

export const createOrganization = async (payload) => {
    const response = await axiosLasta.post('/organization', payload, {
        cache: {
            prune: [CACHE_NAME_ORGANIZATION_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const updateOrganization = async (payload) => {
    const response = await axiosLasta.put('/organization', payload, {
        cache: {
            prune: [CACHE_NAME_ORGANIZATION_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const fetchOrganizationLocationsByOrganizationId = async (id) => {
    const response = await axiosLasta.get(`/location/organization/${id}`, {
        params: {
            status: true,
        },
    });
    const { data } = response;
    return data;
};

export const fetchOrgAssignableUserList = async (organizationId) => {
    const payload = toQueryString({ organizationId: organizationId });
    const response = await axiosLasta(`/organization/assignable-users?${payload}`);
    const { data } = response;
    return data;
};

export const fetchOrganizationById = async (organizationId) => {
    const response = await axiosLasta(`/organization/id/${organizationId}`);
    const { data } = response;
    return data;
};
