import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FormOrganizationSaga } from 'store/formControl/formOrganizationSaga';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';
import { organizationListActions } from './organizationListSlice';
import { getAllOrganizationList } from 'services/organizationService';

export function* fetchMasterList() {
    try {
        const data = yield getAllOrganizationList();
        yield put(organizationListActions.fetchMasterListSuccess(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingMasterListStart() {
    yield throttleAction(organizationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(organizationListActions.submitFormStart, FormOrganizationSaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(organizationListActions.addItemToMasterList.type, FormOrganizationSaga);
}

export function* organizationListSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart)]);
}
