const DEFAULT_EXPIRATION = 60 * 60 * 1000; // 60 minutes in milliseconds
const cache = new Map();

const getFromCache = async (key, conf) => {
    const cachedData = cache.get(key);
    if (cachedData) {
        const { data, timestamp } = cachedData;
        const expirationInMilliseconds = conf?.expiration || DEFAULT_EXPIRATION;
        if (Date.now() - timestamp < expirationInMilliseconds) {
            return data;
        } else {
            cache.delete(key);
        }
    }
    return null;
};

const setToCache = async (key, data) => {
    cache.set(key, { data, timestamp: Date.now() });
};

const removeFromCache = async (key) => {
    cache.delete(key);
};

const clear = async () => {
    cache.clear();
};

export const createMemoryCacheStorage = () => ({
    clear,
    get: getFromCache,
    set: setToCache,
    remove: removeFromCache,
});
