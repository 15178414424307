import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import KeyIcon from '@mui/icons-material/Key';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShieldIcon from '@mui/icons-material/Shield';
import AppointmentIcon from 'components/icons/AppointmentIcon';
import PersonnelIcon from 'components/icons/PersonnelIcon';
import SSOIcon from 'components/icons/SSOIcon';
import Tollbooth from 'components/icons/Tollbooth';
import YardCheckIcon from 'components/icons/YardCheckIcon';
import InventoryIcon from 'components/icons/InventoryIcon';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import { ROLES, ROUTE_PATH } from 'utils/constants';

export const getInventoryRoutes = (featureFlags) => {
    const { isInventoryEnabled, isYardCheckEnabled } = featureFlags;

    if (!isInventoryEnabled && !isYardCheckEnabled) {
        return null;
    }

    const inventoryRoutes = [];

    if (isInventoryEnabled) {
        inventoryRoutes.push({
            Icon: InventoryIcon,
            path: '/inventory-list',
            text: 'List',
        });
    }

    if (isYardCheckEnabled) {
        inventoryRoutes.push({
            Icon: YardCheckIcon,
            path: '/yard-check-list',
            text: 'Yard Check',
        });
    }

    return {
        text: 'Inventory',
        Icon: InventoryIcon,
        allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        items: inventoryRoutes,
    };
};

export const getAvailableRoutes = (featureFlags) => {
    const routes = [];
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.AGENT, ROLES.HQ_SUPPORT, ROLES.USER, ROLES.TECH, ROLES.SALES],
        path: '/',
        text: 'Homepage',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        path: '/access-point-record-list',
        text: 'Access Point Records',
        Icon: Tollbooth,
    });

    const inventoryRoutes = getInventoryRoutes(featureFlags);

    if (inventoryRoutes) {
        routes.push(inventoryRoutes);
    }

    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        path: '/appointment-list',
        text: 'Appointments',
        Icon: AppointmentIcon,
    });

    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.USER],
        path: '/employees',
        text: 'Employees',
    });
    routes.push({
        text: 'Authorized Lists',
        Icon: ShieldIcon,
        allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
        items: [
            {
                Icon: HowToRegIcon,
                path: '/people-authorized-list',
                text: 'People',
            },
            {
                Icon: ListAltIcon,
                path: '/company-authorized-list',
                text: 'Organizations',
            },
        ],
    });
    if (featureFlags.isSsoEnabled) {
        routes.push({
            allowedRoles: [ROLES.ORGANIZATION_ADMIN],
            path: ROUTE_PATH.SSO_CONFIGURATION,
            text: 'SSO Configuration',
            Icon: SSOIcon,
        });
    }
    routes.push({
        allowedRoles: [ROLES.ADMIN],
        Icon: KeyIcon,
        path: '/authorized-organization-locations',
        text: 'Authorized Organizations & Locations',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        text: 'Monitoring Elements',
        items: [
            {
                path: '/monitoring-elements',
                text: 'Monitoring Elements',
            },
            {
                path: '/monitoring-elements/audio',
                text: 'Audio',
            },
            {
                path: '/monitoring-elements/relay',
                text: 'Relays',
            },
        ],
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/organization-list',
        text: 'Organizations',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN],
        path: '/client-personnel',
        text: 'Client Personnel',
        Icon: PersonnelIcon,
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/location-list',
        text: 'Locations',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/divison-list',
        text: 'Divisions',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/camera-tron',
        text: 'CameraTron',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/control-center',
        text: 'Control Center',
    });
    routes.push({
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-object',
        text: 'Monitoring Object',
    });
    routes.push({
        text: 'Alisa',
        items: [{ path: '/regions-of-interest', text: 'Regions Of Interest' }],
    });

    if (featureFlags.isClientRequestEnabled) {
        routes.push({
            allowedRoles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN],
            path: '/request-tickets',
            text: 'Request',
            Icon: ConfirmationNumberIcon,
        });
    }

    if (featureFlags.isDynamicFormsEnabled) {
        routes.push({
            allowedRoles: [ROLES.ADMIN],
            Icon: DynamicFormIcon,
            text: 'Dynamic Forms',
            items: [
                { Icon: DynamicFormIcon, path: '/dynamic-form-list', text: 'Dynamic Forms' },
                { Icon: ViewAgendaIcon, path: '/section-template-list', text: 'Section Templates' },
                { Icon: DashboardCustomizeIcon, path: '/fields-template-list', text: 'Fields Templates' },
            ],
        });
    }

    return routes;
};
