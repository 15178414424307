import { axiosLasta } from 'config';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FormLocationSaga } from 'store/formControl/formLocationSaga';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';
import { locationListActions } from './locationListSlice';
import { getAllOrganizationList } from 'services/organizationService';

export function* fetchMasterList() {
    try {
        const response = yield axiosLasta(`/location/all-with-inactive`);
        const { data } = response;
        const modifiedList = [];
        data.forEach((el) => {
            const formatedData = {
                ...el,
                organizationName: el.organization?.name,
                organization: el.organization?.id,
                division: el.division?.id,
                divisionName: el.division?.name,
            };

            modifiedList.push(formatedData);
        });
        yield put(locationListActions.fetchMasterListSuccess(modifiedList));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* fetchOrganizations() {
    try {
        const data = yield getAllOrganizationList();
        data.sort(function (a, b) {
            var textA = a?.name?.toUpperCase();
            var textB = b?.name?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(locationListActions.fetchOrganizationsSuccess(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onfetchOrganizations() {
    yield throttleAction(locationListActions.fetchOrganizations.type, fetchOrganizations);
}

export function* onFetchingMasterListStart() {
    yield throttleAction(locationListActions.fetchMasterListStart.type, fetchMasterList);
}

export function* onSubmitFormStart() {
    yield takeLatest(locationListActions.submitFormStart, FormLocationSaga);
}

export function* onAddItemToMasterList() {
    yield takeLatest(locationListActions.addItemToMasterList.type, FormLocationSaga);
}

export function* locationListSaga() {
    yield all([call(onSubmitFormStart), call(onAddItemToMasterList), call(onFetchingMasterListStart), call(onfetchOrganizations)]);
}
