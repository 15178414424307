import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import styles from 'features/DefaultHelp.module.css';

const HelpDialog = () => {
    const instructions = [
        {
            key: 'organization',
            title: 'Assigning a Facility Manager to an organization(s)',
        },
        {
            key: 'location',
            title: 'Assigning a Facility Manager to a location(s)',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);

    const getInstructionComponent = (key) => {
        switch (key) {
            case 'organization':
                return renderOrganizationInstruction();
            default:
                return renderLocationInstruction();
        }
    };

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderOrganizationInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Assigning a Facility Manager to an organization(s)</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Locate the user in the table.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Click the <SettingsOutlinedIcon className={`${styles['gear-icon']} ${styles['inlined-icon']}`} /> in the Options column.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>In the pop-up, check the organization(s) to assign and click Save.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        After assigning organizations, use the same{' '}
                        <SettingsOutlinedIcon className={`${styles['gear-icon']} ${styles['inlined-icon']}`} /> to deselect the given organization to
                        remove access.
                    </span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderLocationInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Assigning a Facility Manager to a location(s)</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Click the <SettingsOutlinedIcon className={`${styles['gear-icon']} ${styles['inlined-icon']}`} /> again to view the locations
                        under the assigned organizations.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Select or deselect locations to assign/unassign access for the Facility Manager.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Save changes to update the user&apos;s access.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <p className={styles['description']}>
                Manage Facility Managers by assigning them to organizations and controlling their access to specific locations. You can:
            </p>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Assign users with the Facility Manager role to one or more organizations</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Assign or unassign locations under assigned organizations to control user access</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>View and manage the list of users, organizations, and locations efficiently</span>
                </ListItemText>
            </List>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Modify record</span>
                            <span>
                                <SettingsOutlinedIcon className={`${styles['gear-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Assign/Unassign organizations & locations.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Search and Filter</span>
                        </TableCell>
                        <TableCell>Retrieve desired results based on specific criteria.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="13rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                                    ${selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInstructionComponent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderInstructionsSection()}
                {renderDivider()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialog;
