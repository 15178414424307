import { axiosLasta } from 'config';
import { CACHE_NAME_AUTH_TYPE_LIST, CACHE_NAME_CLIENT_PERSONNEL_LIST } from 'services/constants/cacheNameConstants';

export const fetchAuthTypeList = async () => {
    const url = '/client/authorization-type/all';
    const response = await axiosLasta(url, {
        cache: {
            enabled: true,
            key: CACHE_NAME_AUTH_TYPE_LIST,
        },
    });
    const { data } = response;
    return data;
};

export const createPersonnelAndAccess = async (payload) => {
    return axiosLasta.post('/client/personnel-and-access', payload, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST],
        },
    });
};
