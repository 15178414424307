import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadPdf from 'components/icons/DownloadPdf';
import React, { useState } from 'react';

import { Avatar } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import styles from 'features/DefaultHelp.module.css';

const HelpDialog = () => {
    const instructions = [
        {
            key: 'addNew',
            title: 'Adding a New Appointment',
        },
        {
            key: 'edit',
            title: 'Editing an Existing Appointment',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);

    const getInstructionComponent = (key) => {
        switch (key) {
            case 'edit':
                return renderEditInstruction();
            default:
                return renderAddNewInstruction();
        }
    };

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderAddNewInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Adding a New Appointment</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Click on the</span>
                    <span>
                        <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                    </span>
                    <span>button.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Fill out the form with required information.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click “Save” to add the new appointment.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderEditInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Editing an Existing Appointment</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Click on the</span>
                    <span>
                        <MoreVertIcon className={`${styles['inlined-icon']}`} />
                    </span>
                    <span>button or double-click an appointment.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Update the appointment’s information as desired.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click “Save” to update the changes.</span>
                </ListItemText>
            </List>
            <span>
                <b>Note: </b> You cannot edit any appointments with a status of <b>Completed</b>.
            </span>
        </Box>
    );

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <p className={styles['description']}>Manage and monitor scheduled appointments for facility access. You can:</p>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Schedule new appointments</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>View and filter appointment statuses</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Track appointment details and durations</span>
                </ListItemText>
            </List>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Add new</span>
                            <span>
                                <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Add new record.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Modify record</span>
                            <span>
                                <MoreVertIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Edit the appointment’s information.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Download options</span>
                            <span>
                                <DownloadPdf className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Export current appointment records in preferred file format.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderStatusText = (bgcolor, text) => (
        <Box display={'flex'} alignItems={'center'}>
            <Avatar sx={{ bgcolor, height: '1.25rem', width: '1.25rem', marginRight: '0.5rem' }}>
                <></>
            </Avatar>
            <p>{text}</p>
        </Box>
    );

    const renderStatusSection = () => (
        <>
            <h4>STATUS INDICATOR</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{renderStatusText(orange[500], 'Active')}</TableCell>
                        <TableCell>The appointment is scheduled and active.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{renderStatusText(red[500], 'Inactive')}</TableCell>
                        <TableCell>The appointment is inactive and requires attention.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{renderStatusText(green[500], 'Completed')}</TableCell>
                        <TableCell>The appointment has been completed successfully.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="12rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                                    ${selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInstructionComponent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderStatusSection()}
                {renderDivider()}
                {renderInstructionsSection()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialog;
