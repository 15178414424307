import { ACCESS_POINT_RECORDS_REQUEST_PAGE_SIZE } from 'components/accessPointRecords/constants/accessPointRecordTableConstant.js';
import { axiosLasta } from 'config';
import { groupFieldsByHeader } from 'utils/formFieldHelper';
import { toQueryString } from 'utils/queryStringHelpers.js';

export const fetchAccessPointRecordsByLocation = async (locationId, filters) => {
    const pageNum = 0;
    const size = ACCESS_POINT_RECORDS_REQUEST_PAGE_SIZE;
    const { startDate, endDate } = filters ?? {};
    const query = toQueryString({
        locationId,
        size,
        page: pageNum,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
    });
    const response = await axiosLasta(`/access-point-records?${query}`);
    const { data } = response;
    return data;
};

export const fetchAccessPointRecordsColumnByLocation = async (locationId) => {
    const response = await axiosLasta(`/access-point-records/columns?locationId=${locationId}`);
    const { data } = response;
    const groupedFields = groupFieldsByHeader(data?.fields);
    return groupedFields;
};

export const fetchAccessPointRecordsFormFieldsByLocation = async (locationId) => {
    const response = await axiosLasta(`/access-point-records/form/fields?locationId=${locationId}`);
    const { data } = response;
    const groupedFields = groupFieldsByHeader(data?.fields);
    return groupedFields;
};

export const createAccessPointRecord = async (payload) => {
    const response = await axiosLasta.post('/access-point-records', payload);
    return response.data;
};

export const updateAccessPointRecord = async (payload, id) => {
    const response = await axiosLasta.put(`/access-point-records/${id}`, payload);
    return response.data;
};

export const removeAccessPointRecordsByLocation = async (transactionId) => {
    const response = await axiosLasta.delete(`/access-point-records/delete/${transactionId}`);
    const { data } = response;
    return data;
};

export const inventoryItemTypesTransaction = async (locationId, payload) => {
    const response = await axiosLasta.post('/inventory-item-types/from-truck-transaction', {
        ...payload,
        gate: { ...payload.gate, location: { id: locationId } },
    });
    return response.data;
};
